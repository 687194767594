<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <template v-if="id == null">

            <h1>
              <div>Создание конфигурации чек-листа индекса клиентоцентричности СЗН
                <v-btn outlined @click="$refs['fileInput'].click()">загрузить файл конфигурации</v-btn>
                <input ref="fileInput" style="display: none" type="file" @change="loadJson">
              </div>
            </h1>
          </template>
          <template v-else>
            <h1>Редактирование<br> конфигурации id {{ config.id }}</h1>
            <div class="creation-date">
              Дата создания<span>{{ config.createdDate | dateFormat }}</span>
            </div>
            <div class="creation-date">
              Дата изменения<span v-if="config.modifiedDate">{{ config.modifiedDate | dateFormat }}</span>
              <span v-else>—</span>
            </div>
          </template>

          <h4>Наименование</h4>
          <v-text-field label="Наименование" v-model="config.title"></v-text-field>

          <div class="list config">
            <div v-for="(indexGroup, indexGroupIndex) in config.configParams.indexGroups" :key="indexGroup.id" class="list-item-wrap">
              <v-expansion-panels>
                <v-expansion-panel :key="indexGroup.id">
                  <v-expansion-panel-header inside @keyup.space.prevent expand-icon="">
                    <div class="list-item">
                      <div class="list-item-name">
                        <button class="toggleSwitch">
                          <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                          </svg>
                          <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                          </svg>
                        </button>
                        <span>
                          <h4 v-if="indexGroupIndex === 0">Группа показателей</h4>
                          <q>{{ indexGroup.id / 100 }}.</q>
                          <v-text-field v-model="indexGroup.title" @click.stop="" label="Наименование группы показателей"></v-text-field>
                        </span>
                        <div v-if="config.configParams.indexGroups.length > 1"
                             class="delete"
                             @click.stop="deleteIndexGroup(config.configParams.indexGroups, indexGroup.id)">
                          <v-icon color="#0033A0">mdi-delete-outline</v-icon>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-expansion-panels class="justify-start">
                      <v-expansion-panel v-for="(index, indexIndex) in indexGroup.indices"
                                         :key="indexGroup.id+'.'+index.id" class="level2">
                        <div class="list-item-wrap">
                          <v-expansion-panel-header @keyup.space.prevent expand-icon="">
                            <div class="list-item">
                              <div class="list-item-name">
                                <button class="toggleSwitch whiteBg">
                                  <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                                  </svg>
                                  <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                                  </svg>
                                </button>
                                <span>
                                  <h4 v-if="indexIndex === 0">Показатель</h4>
                                  <q>{{ indexGroup.id / 100 }}.{{ index.id / 100 }}</q>
                                  <v-text-field v-model="index.title" @click.stop="" label="Наименование показателя"></v-text-field>
                                </span>
                                <div v-if="indexGroup.indices.length > 1"
                                     class="delete"
                                     @click.stop="deleteIndex(config.configParams.indexGroups, indexGroup.id, index.id)">
                                  <v-icon color="#0033A0">mdi-delete-outline</v-icon>
                                </div>
                              </div>
                              <div class="list-item-type">
                                <table>
                                  <tr>
                                    <td>
                                      <v-select
                                          label="Тип параметра"
                                          :items="indexType"
                                          item-text="title"
                                          item-value="type"
                                          v-model="index.questionType"
                                      ></v-select>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content class="level3">
                            <div v-for="(parameter, parameterIndex) in index.parameters"
                                 :key="indexGroup.id+'.'+index.id+'.'+parameter.id" class="list-item">
                              <div class="list-item-name">
                                <span>
                                  <h4 v-if="parameterIndex === 0">Параметр</h4>
                                  <q>{{ indexGroup.id / 100 }}.{{ index.id / 100 }}.{{ parameter.id / 100 }}</q>
                                  <v-text-field v-model="parameter.title" label="Наименование параметра"></v-text-field>
                                </span>
                                <div v-if="index.parameters.length > 1"
                                     class="delete"
                                     @click.stop="deleteParameter(config.configParams.indexGroups, indexGroup.id, index.id, parameter.id)">
                                  <v-icon color="#0033A0">mdi-delete-outline</v-icon>
                                </div>
                              </div>
                              <div class="list-item-type">
                                <table>
                                  <tr>
                                    <td>
                                      <v-checkbox label="Возможность прикрепления файлов" v-model="parameter.canUploadFile"></v-checkbox>
                                    </td>
                                    <td>
                                      <v-checkbox label="Только для управляющего ЦЗН" v-model="parameter.onlyManagerCzn"></v-checkbox>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <a v-if="index.questionType === 'COMMENT'"
                               class="configAdd" style="margin-left: 0px; margin-top: -24px!important;" href="#"
                               @click.prevent="addParameter(index)">+ Добавить параметр</a>
                          </v-expansion-panel-content>
                        </div>
                      </v-expansion-panel>
                      <a style="margin-left: 80px;" class="configAdd" href="#" @click.prevent="addIndex(indexGroup)">+ Добавить показатель</a>
                    </v-expansion-panels>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <a class="configAdd" href="#" @click.prevent="addIndexGroup(config.configParams)">+ Добавить группу показателей</a>
          </div>

          <div class="save-buttons mt-24">
            <v-btn color="primary" outlined @click="$router.push('/config/clientCentricity')">Отмена</v-btn>
            <v-btn :disabled="submitButtonDisabled" color="primary" @click="submit">Сохранить изменения</v-btn>
          </div>
        </div>
        <div class="error" style="position: fixed;bottom: 0" v-if="errorText!=null">
          Ошибка: {{errorText}}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<script>
// import api from "@/modules/api";

export default {
  name: "ClientCentricityConfigEdit",
  data() {
    return {
      id: null,
      apiLoaded: false,
      errorText: null,
      submitButtonDisabled: false,
      attachedFiles: [''],
      config: {
        configParams: {
          indexGroups: [
            {id: 100,
              title: "",
              indices: [
                {id: 100,
                  title: "",
                  parameters: [
                    {id: 100,
                      title: "",
                      canUploadFile: true,
                      onlyManagerCzn: false,
                    }
                  ]}
              ]}
          ]}
      },
      indexType: [
        {type: 'COMMENT', title: 'Комментарий', description: 'Текстовое поле'},
        {type: 'EVENTS', title: 'Мероприятия', description: 'Тип вопроса, в котором пользователи перечисляют проведенные мероприятия'},
        {type: 'DYNAMIC', title: 'Динамика', description: 'Два числовых значения'},
        {type: 'REACTION', title: 'Быстрое реагирование', description: 'Обозначение наличия системы анализа показателей скорости обслуживания клиентов'},
        {type: 'LEVEL', title: 'Уровень', description: 'Выбор уровня комфортности'},
      ],
      inputRules: [value => !value || value.size < 50000000 || 'Размер файла должен быть менее 50 MB'],
      regionList: null,
      allCZNinRegionAreSelected: [],
    };
  },

  methods: {

    //скачивает конфигурацию с бэка
    async loadConfig(id) {
      let req = await this.$getApi("/clientCentricity/config/getConfig", {id: id})
      // console.log('req', req)
      if (req.ok) {
        // console.log('req.payload', req.payload)
        this.config = req.payload
        this.config.configParams = JSON.parse(this.config.clientCentricityJson)
        this.config.title = this.config.configParams.title;
        this.apiLoaded = true
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    deleteIndexGroup(config, indexGroupId) {
      for (let i = 0; i < config.length; i++) {
        if (config[i].id === indexGroupId) {
          config.splice(i, 1)
          break
        }
      }
      this.ordinateNumbers()
      this.$forceUpdate()
    },

    deleteIndex(config, indexGroupId, indexId) {
      for (let indexGroup of config) {
        if (indexGroup.id === indexGroupId) {
          for (let i = 0; i < indexGroup.indices.length; i++) {
            if (indexGroup.indices[i].id === indexId) {
              indexGroup.indices.splice(i, 1)
              break;
            }
          }
          break
        }
      }
      this.ordinateNumbers()
      this.$forceUpdate()
    },

    deleteParameter(config, indexGroupId, indexId, parameterId) {
      for (let indexGroup of config) {
        if (indexGroup.id === indexGroupId) {
          for (let index of indexGroup.indices) {
            if (index.id === indexId) {
              for (let i = 0; i < index.parameters.length; i++) {
                if (index.parameters[i].id === parameterId) {
                  index.parameters.splice(i, 1)
                  break;
                }
              }
              break;
            }
          }
          break
        }
      }
      this.ordinateNumbers()
      this.$forceUpdate()
    },

    addIndexGroup(config) {
      if(config.indexGroups == null){
        config.indexGroups = []
      }
      config.indexGroups.push({
        id: 100,
        title: "",
        indices: [{id: 100, title: "", parameters: [{id: 100, title: "", canUploadFile: true, onlyManagerCzn: false}]}]
      })
      this.ordinateNumbers()
      this.$forceUpdate();
    },

    addIndex(indexGroup) {
      indexGroup.indices.push({id: 100, title: "", parameters: [{id: 100, title: "", canUploadFile: true, onlyManagerCzn: false}]})
      this.ordinateNumbers()
      this.$forceUpdate();
    },

    addParameter(index) {
      index.parameters.push({id: 100, title: "", canUploadFile: true, onlyManagerCzn: false})
      this.ordinateNumbers()
      this.$forceUpdate();
    },

    //после удаления какого-то элемента порядок номеров разделов-групп показателей-показателей собъется,
    //и нужно все снова расставить. При добавлении - аналогично
    ordinateNumbers() {
      for (let i = 0; i < this.config.configParams.indexGroups.length; i++) {
        let indexGroup = this.config.configParams.indexGroups[i]
        indexGroup.id = i * 100 + 100
        for (let j = 0; j < indexGroup.indices.length; j++) {
          let index = indexGroup.indices[j]
          index.id = j * 100 + 100
          for (let k = 0; k < index.parameters.length; k++) {
            let parameter = index.parameters[k]
            parameter.id = k * 100 + 100
          }
        }
      }
    },

    //кнопка "Сохнанить изменения"
    async submit() {
      let config = {},
          validStat = this.validateConfig(this.config);
      if (validStat === true) {
        config = this.formConfig();
        config.clientCentricityJson = JSON.stringify(config.clientCentricityJson);
        // console.log('config submit', config);
        if (this.id == null) {
          await this.saveConfig(config, "/clientCentricity/config/saveConfig");
        }
      } else {
        this.errorText = validStat
      }
    },

    async saveConfig(config, path) {
      this.submitButtonDisabled = true
      let req = await this.$postApi(path, config)
      // console.log ('req', req)
      if (req.ok) {
        this.id = req.payload.id;
        this.$router.push("/config/clientCentricity");
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
      this.submitButtonDisabled = false
    },

    validateConfig(config) {
      console.log(config)
      // if (config.title == null || config.title.length < 1) {
      //   return "Должно быть указано наименование типового мониторинга"
      // }
      // if (config.configParams.description == null || config.configParams.description.length < 1) {
      //   return "Должно быть указано описание типового мониторинга"
      // }
      // let validNom = this.validateIndexGroup(config.configParams.indexGroups);
      // if (!(validNom === true)) {
      //   return validNom
      // }
      // if (config.configParams.type === "region") {
      //   if (config.configParams.regions.length === 0) {
      //     return "Должен быть выбран хотя бы один регион"
      //   }
      // } else if (config.configParams.type === "czn"){
      //   if (config.configParams.czns.length === 0) {
      //     return "Должен быть выбран хотя бы один ЦЗН"
      //   }
      // }
      return true
    },

    validateIndexGroup(indexGroups) {
      if (indexGroups == null || indexGroups.length < 1) {
        return "Должен быть хотя бы один раздел"
      }
      for (let i = 0; i < indexGroups.length; i++) {
        let indexGroup = indexGroups[i];
        if (indexGroup.title === "" || indexGroup.title.length === 0) {
          return "Должно быть указано наименование раздела " + (i + 1)
        }
        if (indexGroup.id !== i * 100 + 100) {
          return "Идентификаторы разделов должны быть кратны 100 и следовать по порядку (раздел "+ (i + 1) + ")"
        }
        for (let j = 0; j < indexGroup.indices.length; j++) {
          let index = indexGroup.indices[j];
          if (index.title === "" || index.title.length === 0) {
            return "Должно быть указано наименование показателя " + (i + 1) + "." + (j + 1)
          }
          if (index.id !== j * 100 + 100) {
            return "Идентификаторы показателей должны быть кратны 100 и следовать по порядку " +
                "(показатель " + (i + 1) + "." + (j + 1) + ")"
          }
          for (let k = 0; k < index.parameters.length; k++) {
            let parameter = index.parameters[k];
            if (parameter.title === "" || parameter.title.length === 0) {
              return "Должно быть указано наименование параметра " + (i + 1) + "." + (j + 1) + "." + (k + 1)
            }
            if (parameter.id !== k * 100 + 100) {
              return "Идентификаторы параметра должны быть кратны 100 и следовать по порядку " +
                  "(параметр " + (i + 1) + "." + (j + 1) + "." + (k + 1) + ")"
            }
          }
        }
      }
      return true
    },

    formConfig() {
      let config = {};
      if ("id" in this.config) {
        config.id = this.config.id;
      }
      if ("createdDate" in this.config) {
        config.createdDate = this.config.createdDate;
      }
      if ("modifiedDate" in this.config) {
        config.modifiedDate = this.config.modifiedDate;
      }
      // config.active = true
      config.title = this.config.title;
      config.clientCentricityJson = {
        title: this.config.title,
      }
      config.clientCentricityJson.indexGroups = this.config.configParams.indexGroups;
      // console.log('config.clientCentricityJson', config.clientCentricityJson)
      for (let i = 0; i < config.clientCentricityJson.indexGroups.length; i++) {
        // config.clientCentricityJson.indexGroups[i].indexGroupName = "indexGroup" + (i + 1);
        for (let j = 0; j < config.clientCentricityJson.indexGroups[i].indices.length; j++) {
          // config.clientCentricityJson.indexGroups[i].indices[j].indexName = "indexGroup" + (i + 1) + "_index" + (j + 1);
          config.clientCentricityJson.indexGroups[i].indices[j].type = "CHECKLIST";

          if (config.clientCentricityJson.indexGroups[i].indices[j].questionType === 'EVENTS') {

            config.clientCentricityJson.indexGroups[i].indices[j].parameters[0].parameterName = "indexGroup" + (i + 1) + "_index" + (j + 1) + "_param1" + "_count";
            config.clientCentricityJson.indexGroups[i].indices[j].parameters[1] = JSON.parse(JSON.stringify(config.clientCentricityJson.indexGroups[i].indices[j].parameters[0]));
            config.clientCentricityJson.indexGroups[i].indices[j].parameters[1].parameterName = "indexGroup" + (i + 1) + "_index" + (j + 1) + "_param1" + "_events";

          } else if (config.clientCentricityJson.indexGroups[i].indices[j].questionType === 'DYNAMIC') {

            config.clientCentricityJson.indexGroups[i].indices[j].parameters[0].parameterName = "indexGroup" + (i + 1) + "_index" + (j + 1) + "_param1" + "_current";
            config.clientCentricityJson.indexGroups[i].indices[j].parameters[1] = JSON.parse(JSON.stringify(config.clientCentricityJson.indexGroups[i].indices[j].parameters[0]));
            config.clientCentricityJson.indexGroups[i].indices[j].parameters[1].parameterName = "indexGroup" + (i + 1) + "_index" + (j + 1) + "_param1" + "_previous";

          } else {
            for (let k = 0; k < config.clientCentricityJson.indexGroups[i].indices[j].parameters.length; k++) {
              config.clientCentricityJson.indexGroups[i].indices[j].parameters[k].parameterName = "indexGroup" + (i + 1) + "_index" + (j + 1) + "_param" + (k + 1);
            }
          }
        }
      }
      // console.log('configFormed', config)
      return config
    },

    loadJson() {
      let file = this.$refs['fileInput'].files[0];
      let reader = new FileReader();
      reader.readAsText(file);
      reader.componentThis = this;
      reader.onload = function () {
        let indexGroups
        try {
          indexGroups = JSON.parse(reader.result)
        } catch (e) {
          reader.componentThis.errorText = "Файл должен содержать конфигурацию в формате JSON"
          return
        }
        let validStat = reader.componentThis.validateIndexGroup(indexGroups.indexGroups)
        if (validStat === true) {
          reader.componentThis.config.configParams.indexGroups = indexGroups.indexGroups
          // console.log('reader.componentThis.config.configParams.indexGroups', reader.componentThis.config.configParams.indexGroups)
        } else {
          reader.componentThis.errorText = validStat
        }
      };
      this.$refs['fileInput'].value = ""
      reader.onerror = function () {
        alert('Ошибка загрузки файла')
      };
    },
  },

  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },

  async beforeMount() {
    this.id = this.$route.params.id;
    if (this.id != null) {
      //режим редактирования
      await this.loadConfig(this.id);
    } else {
      this.apiLoaded = true;
    }
  }
}
</script>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style scoped lang="scss">
//.regions-list {
//  columns: 2;
//  list-style: none;
//  padding: 0;
//}

.file-input {
  margin-top: 15px;
  .v-file-input {
    max-width: 50%;
  }
  .v-input--dense .v-input__icon.v-input__icon--clear {
    margin-top: 0 !important;
  }
}
.file-input + .file-input {
  margin-top: 0;
}

.region-list-wrap {
  .region-buttons {
    display: flex;
    margin: 10px 0;
    .btn, button {
      margin-right: 8px;
      margin-left: 0;
      height: 30px;
    }
  }
  .region-list {
    max-height: 45vh;
    overflow-x: auto;
    .v-input--checkbox {
      margin: 0 0;
    }
  }
}

.file-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 20px;

  .v-icon {
    margin-right: 10px;
    color: #0033a0;
  }
}

.list.config {
  margin-top: 8px;
}

.list-item-type {
  //float: left;
  tr {
    margin-top: -20px;
  }
  span {
    width: 100px;
    margin-left: 30px;
  }
  .v-input {
    width: calc(100% - 100px);
    flex-grow: 0;
    margin-left: 30px;
  }
  .v-select {
    margin-top: -20px;
    margin-bottom: -25px;
    width: 250px;
    .v-label {
      color: $cgray!important;
    }
  }
}

.list-item.list-item-name {
  display: flex;
  align-items: center;
  .toggleSwitch {
    align-self: center;
  }

}

.region-list-wrap .list-item.list-item-name {
  .v-input--checkbox {
    margin: 4px 0 0 10px !important;
  }
}

.v-expansion-panel .level2 {
  padding-left: 40px;
  padding-top: 0;
}

</style>